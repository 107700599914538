import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import Wedding from "./Components/Wedding";

function App() {

  return (
    <div className="App">
     <Wedding/>
    </div>
  );
}

export default App;
